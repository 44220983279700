import React, { useCallback, useMemo, useState } from 'react';
import cls from 'classnames';

import Container from 'components/base/Container';

import Title, {
  TAG_VARIANTS as TITLE_TAGS,
  COLOR_VARIANTS as TITLE_COLORS,
} from 'components/atoms/Title/Component';
import Paragraphs, {
  COLOR_VARIANTS as PARAGRAPHS_COLORS,
} from 'components/atoms/Paragraphs/Component';
import Button, { COLOR_VARIANTS as BUTTON_COLORS } from 'components/atoms/Button/Component';

import useIsMounted from 'hooks/useIsMounted';
import useSendEvent from 'hooks/useSendEvent';
import useScrollToAnchor from 'hooks/useScrollToAnchor';

import ArrowDown from './blocks/ArrowDown';
import SubscribeModal from './blocks/SubscribeModal';

import useBackgroundSource from '../../hooks/useBackgroundSource';
import useParagraphsAlign from '../../hooks/useParagraphsAlign';
import useActionSize from '../../hooks/useActionSize';
import useTitleAlign from '../hooks/useTitleAlign';

import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import componentStyles from './Component.module.scss';

function MainScreen({
  anchor,
  title,
  paragraphs,
  action,
  backgroundImage,
  mobileIllustration,
  tabletIllustration,
  showArrowDown,
  form,
  subscribeModal,
  modals,
}) {
  const isMounted = useIsMounted();

  const sendEventCreator = useSendEvent();
  const scrollToActionHref = useScrollToAnchor(action?.href);

  const titleAlign = useTitleAlign(isMounted);
  const backgroundSource = useBackgroundSource(isMounted, backgroundImage);
  const paragraphsAlign = useParagraphsAlign({ isMounted });
  const actionSize = useActionSize(isMounted);

  const canShowSubscribeModal = useMemo(() => form !== undefined && subscribeModal !== undefined, [
    form,
    subscribeModal,
  ]);

  const [showSubscribeModal, setSubscribeModalShowingTo] = useState(false);
  const closeSubscribeModalHandler = useCallback(() => setSubscribeModalShowingTo(false), []);

  const actionClickHandler = useCallback(
    (event) => {
      sendEventCreator({ eventObj: action.eventData })();

      if (canShowSubscribeModal) {
        setSubscribeModalShowingTo(true);
        event.preventDefault();
        return;
      }

      if (!action.href?.startsWith('#')) {
        return;
      }

      scrollToActionHref(event);
    },
    [canShowSubscribeModal, scrollToActionHref, sendEventCreator, action],
  );

  const renderAction = useCallback(() => {
    if (!action) {
      return null;
    }

    return (
      <div className={commonStyles.actionWrapper}>
        <Button
          className={commonStyles.action}
          href={action.href}
          onClick={actionClickHandler}
          size={actionSize}
          color={BUTTON_COLORS.BASE_DARK}
        >
          {action.text}
        </Button>
      </div>
    );
  }, [action, actionClickHandler, actionSize]);

  return (
    <div
      id={anchor}
      className={commonStyles.ComponentRoot}
      style={backgroundSource ? { backgroundImage: `url('${backgroundSource}')` } : undefined}
    >
      <Container>
        <div className={commonStyles.wrapper}>
          <div className={commonStyles.content}>
            {mobileIllustration && (
              <div
                className={cls(commonStyles.illustration, commonStyles.mobileIllustration)}
                style={{ backgroundImage: `url('${mobileIllustration}')` }}
              />
            )}
            {tabletIllustration && (
              <div
                className={cls(commonStyles.illustration, commonStyles.tabletIllustration)}
                style={{ backgroundImage: `url('${tabletIllustration}')` }}
              />
            )}
            <Title
              className={commonStyles.title}
              tag={TITLE_TAGS.h1}
              color={TITLE_COLORS.ACCENT}
              align={titleAlign}
            >
              {title}
            </Title>
            {Boolean(paragraphs) && (
              <Paragraphs
                className={commonStyles.description}
                align={paragraphsAlign}
                color={PARAGRAPHS_COLORS.DARK_400}
              >
                {paragraphs}
              </Paragraphs>
            )}
            {renderAction()}
          </div>
        </div>
      </Container>
      {showArrowDown && (
        <div className={componentStyles.arrowDown}>
          <ArrowDown className={componentStyles.arrowIcon} />
        </div>
      )}
      <SubscribeModal
        isModalAvailable={canShowSubscribeModal}
        showModal={showSubscribeModal}
        onCloseModal={closeSubscribeModalHandler}
        actionSize={actionSize}
        form={form}
        subscribeModal={subscribeModal}
        modals={modals}
      />
    </div>
  );
}

MainScreen.propTypes = propTypes;
MainScreen.defaultProps = defaultProps;

export default MainScreen;
