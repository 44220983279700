import { useCallback } from 'react';

export default function useScrollToAnchor(anchor, yOffset = 0) {
  return useCallback(
    (event) => {
      if (!anchor) {
        return;
      }

      event.preventDefault();

      const anchorId = anchor.startsWith('#') ? anchor.substr(1) : anchor;

      const anchorTargetNode = document.getElementById(anchorId);
      if (!anchorTargetNode) {
        return;
      }

      const top = anchorTargetNode.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top, behavior: 'smooth' });
    },
    [anchor, yOffset],
  );
}
