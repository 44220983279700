import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Title, {
  ALIGN_VARIANTS as TITLE_ALIGNS,
  SIZE_VARIANTS as TITLE_SIZES,
  TAG_VARIANTS as TITLE_TAGS,
} from 'components/atoms/Title/Component';
import Paragraphs, {
  ALIGN_VARIANTS as PARAGRAPHS_ALIGNS,
  COLOR_VARIANTS as PARAGRAPHS_COLORS,
} from 'components/atoms/Paragraphs/Component';

import Modal from 'components/organisms/Modal';
import SubscribeForm, {
  MODAL_STATUSES,
  useModalHandlers,
} from 'components/organisms/SubscribeForm/Component';

import { subscribeModalProps } from '../../../propTypes';

import styles from './SubscribeModal.module.scss';

function SubscribeModal({
  isModalAvailable,
  showModal,
  onCloseModal,
  actionSize,
  subscribeModal,
  form,
  modals,
}) {
  const [disableOutsideClose, changeDisableOutsideCloseTo] = useState(false);
  const closeSubscribeFormHandler = useCallback(
    (status) => {
      if (status === MODAL_STATUSES.ERROR) {
        return;
      }

      onCloseModal();
      changeDisableOutsideCloseTo(false);
    },
    [onCloseModal],
  );

  const enableOutsideClose = useCallback(() => changeDisableOutsideCloseTo(false), []);

  const { renderSubmitResultModal, successSubmitHandler, errorSubmitHandler } = useModalHandlers(
    modals,
    closeSubscribeFormHandler,
    enableOutsideClose,
  );

  const modalHandlerDecorator = useCallback(
    (handlerAction) => (...arg) => {
      changeDisableOutsideCloseTo(true);
      handlerAction(...arg);
    },
    [],
  );

  const renderSubscribeModalContent = useCallback(
    () => (
      <>
        <Title tag={TITLE_TAGS.h3} size={TITLE_SIZES.div} align={TITLE_ALIGNS.CENTER}>
          {subscribeModal.title}
        </Title>
        {subscribeModal.paragraphs && (
          <Paragraphs
            className={styles.description}
            align={PARAGRAPHS_ALIGNS.CENTER}
            color={PARAGRAPHS_COLORS.DARK_400}
          >
            {subscribeModal.paragraphs}
          </Paragraphs>
        )}
        <SubscribeForm
          className={styles.form}
          actionSize={actionSize}
          onSuccessSubmit={modalHandlerDecorator(successSubmitHandler)}
          onErrorSubmit={modalHandlerDecorator(errorSubmitHandler)}
          {...form}
        />
      </>
    ),
    [
      subscribeModal,
      actionSize,
      modalHandlerDecorator,
      successSubmitHandler,
      errorSubmitHandler,
      form,
    ],
  );

  if (!isModalAvailable) {
    return null;
  }

  return (
    <>
      <Modal
        id="subscribe-modal"
        order={7}
        open={showModal}
        onClose={closeSubscribeFormHandler}
        disableOutsideClose={disableOutsideClose}
      >
        {renderSubscribeModalContent}
      </Modal>
      {renderSubmitResultModal()}
    </>
  );
}

SubscribeModal.propTypes = {
  isModalAvailable: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  ...subscribeModalProps,
};

export default SubscribeModal;
