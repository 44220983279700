import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import styles from './ArrowDown.module.scss';

function ArrowDown({ className, pathClassName }) {
  return (
    <svg
      className={cls(styles.ArrowDown, className)}
      viewBox="0 0 52 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={cls(styles.path, pathClassName)}
        d="M0.102625 1.39266C0.451913 0.325577 1.58453 -0.251115 2.63239 0.104577L25.367 7.82174C25.7775 7.96109 26.2214 7.96109 26.6319 7.82174L49.3665 0.104577C50.4144 -0.251115 51.547 0.325577 51.8963 1.39266C52.2456 2.45973 51.6793 3.61312 50.6314 3.96881L27.8968 11.686C26.6652 12.104 25.3337 12.104 24.1021 11.686L1.36751 3.96881C0.319644 3.61312 -0.246664 2.45973 0.102625 1.39266Z"
      />
    </svg>
  );
}

ArrowDown.propTypes = {
  className: PropTypes.string,
  pathClassName: PropTypes.string,
};

ArrowDown.defaultProps = {
  className: undefined,
  pathClassName: undefined,
};

export default ArrowDown;
